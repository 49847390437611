window.uplistingSearchWidget.init({
  baseUrl: "https://allreadyhome.bookeddirectly.com/g",
  showLocation: true,
  locationText: "All cities",
  locations: ["Greater Manchester", "Sale"],
  color: "#000",
  insertAt: ".uplisting-widget",
  guestsMin: 1,
  guestsMax: 99,
  textColor: "#fff",
  backgroundColor: "#13345d",
  buttonText: "Book now",
});
